import './home.scss';

import React from 'react';

import { ReactComponent as CalendarIcon } from '../../assets/svgs/home-calendar.svg';
import { ReactComponent as PersonIcon } from '../../assets/svgs/home-person.svg';
import { ReactComponent as VehicleIcon } from '../../assets/svgs/home-vehicle.svg';
import { Header } from '../../components/header/Header';
import { UniqueSellingPoints } from '../../components/ups/UniqueSellingPoints';
import { Widget } from '../../components/widget/Widget';
import { useLocalize } from '../../hooks/useLocalize';

export const Home: React.FC<any> = () => {
  const { translate: t, locale } = useLocalize();

  const {
    logoUrl,
    backgroundColor,
    backgroundContrastColor,
    heroImageUrl,
  } = window.tz_globalConfigs.styling;
  const { partnerStylingOverrides } = window.tz_globalConfigs;

  return (
    <div className="homepage" style={{ backgroundColor: partnerStylingOverrides?.['whitelabel.page.backgroundColor'] || backgroundColor }}>
      <div className="homepage-main" style={{
        backgroundImage: !partnerStylingOverrides?.['whitelabel.page.backgroundColor']
          && (partnerStylingOverrides?.['whitelabel.backgroundImageUrl'] || heroImageUrl)
          ? `url(${partnerStylingOverrides?.['whitelabel.backgroundImageUrl'] || heroImageUrl})`
          : 'none',
      }}>
        <div className="homepage-header">
          <div className="homepage-header__content">
            <img src={partnerStylingOverrides?.['whitelabel.logoUrl'] || logoUrl} className="logo-image" alt="" />
            <Header showCurrency={false} showCurrencyStandalone={true}/>
          </div>
        </div>
        <div className="homepage-block">
          <h1 style={{ color: partnerStylingOverrides?.['whitelabel.page.textColor'] || backgroundContrastColor }}>{t('easyTransfers')}</h1>
          <UniqueSellingPoints />
          <Widget />
        </div>
      </div>
      <div className="homepage-content" style={{ color: partnerStylingOverrides?.['whitelabel.page.textColor'] || backgroundContrastColor }}>
        <div className="homepage-payment-methods">
          <img src="/transferz/images/visa.png" alt="available-payment-types" height="28" />
          <img src="/transferz/images/paypal.png" alt="available-payment-types" height="28" />
          <img src="/transferz/images/mastercard.png" alt="available-payment-types" height="28" />
          <img src="/transferz/images/maestro.png" alt="available-payment-types" height="28" />
          <img src="/transferz/images/apple-pay.png" alt="available-payment-types" height="28" />
          <img src="/transferz/images/google-pay.png" alt="available-payment-types" height="28" />
          <img src="/transferz/images/alipay.png" alt="available-payment-types" height="28" />
          <img src="/transferz/images/ideal.png" alt="available-payment-types" height="28" />
          <img src="/transferz/images/discovercard.png" alt="available-payment-types" height="28" />
          <img src="/transferz/images/dinersclub.png" alt="available-payment-types" height="28" />
          <img src="/transferz/images/amex.png" alt="available-payment-types" height="28" />
          <img src="/transferz/images/mada.png" alt="available-payment-types" height="28" />
        </div>
        <p>{t('arrangedInAMinute')}</p>
        <h3>{t('easySteps')}</h3>
        <div className="homepage-content-items">
          <div className="homepage-content-item">
            <CalendarIcon />
            <h5>{t('scheduleInAdvance')}</h5>
            <span>{t('scheduleATime')}</span>
          </div>
          <div className="homepage-content-item">
            <VehicleIcon />
            <h5>{t('vehicleOptions')}</h5>
            <span>{t('chooseACar')}</span>
          </div>
          <div className="homepage-content-item">
            <PersonIcon />
            <h5>{t('payAndRelax')}</h5>
            <span>{t('noHiddenCosts')}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
